<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useDisplay } from 'vuetify';

import { pluralize } from '@/utils/strings/pluralize.util';
import { isProRecentlyActive } from '@/core/sourcing/utils/dates';

import ProSearchResults from '@/components/Search/ProSearchResults/ProSearchResults.vue';
import ProSearchForm from '@/components/Search/ProSearchForm/ProSearchFrom.vue';
import AddToJobModal from '@/components/Search/AddProToJobModal.vue';

import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import SearchEmptyState from '@/assets/png/search-empty-state.png';

import SelectedCandidateService from '@/core/sourcing/selected-candidate/selected-candidate.service';
import { SearchService } from '@/core/sourcing/search/search.service';
import { SearchService as SharedSearchService } from '@/core/shared/search/search.service';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import type { SelectedGatedUserProfile } from '@/core/sourcing/messaging/types/selected-gated-user-profile.type';

import {
  ProUnlockResultStatus,
  ProUnlockSource,
} from '@/core/sourcing/pro-unlock/types/pro-unlock-source.type';

import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { DrawerService } from '@/core/shared/drawer/drawer.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import { MessagingService } from '@/core/sourcing/messaging/messaging.service';
import ProUnlockService from '@/core/sourcing/pro-unlock/pro-unlock.service';
import { InternalError } from '@/core/shared/errors/internal.error';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import ProjectService from '@/core/shared/project/project.service';
import { PageSource } from '@/core/shared/tracking/tracking.type';
import { useRoute, useRouter } from 'vue-router';

const display = useDisplay();
const route = useRoute();
const router = useRouter();

const searchService = new SearchService();
const selectedCandidateService = new SelectedCandidateService();
const messagingService = new MessagingService();
const proUnlockService = new ProUnlockService();
const projectService = new ProjectService();
const sharedSearchService = new SharedSearchService();

const isUnlockingUserProfiles = ref(false);

const isLoading = computed(() => sharedSearchService.isSearchingCandidates);
const openMessagingDialog = ref(false);
const projects = computed(() => projectService.projects);
const selectedPros = computed(() => messagingService.selectedProUserProfiles);
const searchFormRef = ref<typeof ProSearchForm | null>(null);

onMounted(async () => {
  // Need to fetch projects for the 'add to job modal' to work
  if (projects.value.length === 0) {
    fetchProjects();
  }

  const newUrlQuery = await sharedSearchService.resolveUrlQuery(route.query);
  await router.replace({ query: newUrlQuery });

  if (sharedSearchService.alreadyExecutedASearch) {
    applyCandidateListScroll();
    return;
  }

  try {
    sharedSearchService.isSearchingCandidates = true;

    await sharedSearchService.searchCandidatesWithAdditionalInformation();
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    sharedSearchService.isSearchingCandidates = false;
    applyCandidateListScroll();
  }
});

const drawerService = new DrawerService();
const isDesktop = computed(() => display.mdAndUp.value);
const showBulkMessageButton = computed(() => {
  return (
    messagingService.selectedProUserProfiles.length > 0 &&
    messagingService.selectedPPCUserProfiles.length > 0
  );
});
const selectedUserProfilesToUnlockCount = computed(() => {
  return messagingService.selectedUserProfilesToUnlock.length;
});
const bulkMessageRecipientsCount = computed(() => {
  return messagingService.selectedPPCUserProfiles.length;
});

const bulkUnlockButtonMessage = computed(() => {
  const isSameCount = selectedUserProfilesToUnlockCount.value === bulkMessageRecipientsCount.value;

  if (isSameCount) {
    return `Unlock & Add to Job (${selectedUserProfilesToUnlockCount.value})`;
  }

  const unlockText = selectedUserProfilesToUnlockCount.value
    ? `Unlock (${selectedUserProfilesToUnlockCount.value})`
    : '';
  const separator = unlockText ? ' & ' : '';
  const recipientsText = ` Add to Job (${bulkMessageRecipientsCount.value})`;

  return `${unlockText}${separator}${recipientsText}`;
});

async function handleResultsPageChange(page: number) {
  try {
    sharedSearchService.isSearchingCandidates = true;
    await sharedSearchService.searchCandidatesWithAdditionalInformation({ page });

    const newUrlQuery = await sharedSearchService.resolveUrlQuery(route.query, {
      localStateIsPriority: true,
    });

    await router.replace({ query: newUrlQuery });
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    sharedSearchService.isSearchingCandidates = false;
    applyCandidateListScroll(0);
  }
}

async function handleResultsItemsPerPageChange(itemsPerPage: number) {
  try {
    sharedSearchService.isSearchingCandidates = true;

    await sharedSearchService.searchCandidatesWithAdditionalInformation({
      itemsPerPage,
    });

    const newUrlQuery = await sharedSearchService.resolveUrlQuery(route.query, {
      localStateIsPriority: true,
    });

    await router.replace({ query: newUrlQuery });
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    sharedSearchService.isSearchingCandidates = false;
    applyCandidateListScroll(0);
  }
}

async function openMessaging({ source }: { source: ProUnlockSource }) {
  try {
    isUnlockingUserProfiles.value = true;
    const userProfilesToUnlock = messagingService.selectedUserProfilesToUnlock.map(
      (userProfile: SelectedGatedUserProfile) => userProfile.id,
    );

    if (!userProfilesToUnlock?.length) {
      openMessagingDialog.value = true;
      return;
    }

    const subscriptionService = new SubscriptionService();
    const shouldDisplayTrialModal = subscriptionService.shouldDisplayTrialModal({
      unlockCount: userProfilesToUnlock.length,
    });
    if (shouldDisplayTrialModal) {
      messagingService.setIsTrialUpgradeModalVisible({
        visible: true,
        source,
      });
      return;
    }

    const result = await proUnlockService.unlockProUserProfiles(userProfilesToUnlock, source);

    // After unlock, refresh the search results
    await sharedSearchService.searchCandidatesWithAdditionalInformation();

    messagingService.markSelectedUserProfilesAsUnlocked(userProfilesToUnlock);
    openMessagingDialog.value = true;

    // Will be shown only if there are some user profiles that were not unlocked:
    // this is a partial success otherwise we wont show any message
    if (result?.status === ProUnlockResultStatus.PARTIAL_SUCCESS) {
      SnackbarService.cautionAlt(result.message);
    }

    const event =
      messagingService.selectedProUserProfiles?.length > 1
        ? TrackingActionName.BULK_ACTION_START
        : TrackingActionName.MESSAGE_START;

    TrackingService.trackAction(event, {
      num_unlocks: userProfilesToUnlock?.length,
      num_pros: messagingService.selectedProUserProfiles?.length,
      num_recently_active:
        messagingService.selectedProUserProfiles?.filter((pro) =>
          isProRecentlyActive(pro.lastActiveTs),
        )?.length ?? 0,
    });
  } catch (error) {
    ErrorService.captureException(error);
    if (
      error instanceof InternalError &&
      error.status === HttpStatus.FORBIDDEN &&
      error.data?.status === ProUnlockResultStatus.ERROR_LIMIT_REACHED
    ) {
      SnackbarService.criticalAlt(
        '<b>Unlock limit reached:</b> Your unlocks will refresh at the start of your next billing cycle.',
      );
    } else {
      SnackbarService.critical('Could not unlock user profiles, please try again later.');
    }
  } finally {
    isUnlockingUserProfiles.value = false;
  }
}

async function fetchProjects() {
  try {
    await projectService.searchProjects({
      skipOnSameSearch: true,
      skipTracking: false,
      pageSource: PageSource.Search,
    });
    await projectService.searchAvailableProjects();
  } catch (error) {
    ErrorService.captureException(error);
  }
}

const resultsHeaderText = computed(() => {
  return `${searchService.serverItems} ${pluralize(searchService.serverItems, 'candidate')} found`;
});

const showEmptyState = computed(() => searchService.serverItems === undefined);

async function onAddProToJobModalSubmit() {
  const selectedCandidateService = new SelectedCandidateService();
  await selectedCandidateService.refreshCandidate();
  messagingService.removeSelectedProUserProfiles();
}

function onAddProToJobModalClose(params: { messageSent: boolean }) {
  // If the message was sent, fetch the job applicants to update the job applicant list
  if (params.messageSent) {
    sharedSearchService.searchCandidatesWithAdditionalInformation().catch((error) => {
      ErrorService.captureException(error);
    });
  }
  openMessagingDialog.value = false;
  messagingService.removeNonPPCProsFromSelectedUserProfiles();
}

function onAddProToJobModalRemovePro(id: number) {
  messagingService.removeProFromSelectedUserProfiles(id);
}

function onCandidateListScroll(event: Event) {
  if (!event.target) {
    return;
  }

  const candidateList = event.target as HTMLDivElement;
  sharedSearchService.candidateListScrollTop = candidateList.scrollTop;
}

function applyCandidateListScroll(scrollTop?: number) {
  requestAnimationFrame(() => {
    const candidateList = document.getElementsByClassName('candidates-list')?.[0];

    if (candidateList) {
      candidateList.scrollTop = scrollTop ?? sharedSearchService.candidateListScrollTop;
    }
  });
}
</script>

<template>
  <v-navigation-drawer width="336" color="shade-860" :permanent="isDesktop">
    <ProSearchForm
      ref="searchFormRef"
      @clear-selected-candidate="selectedCandidateService.clearSelectedCandidate()"
      @search="applyCandidateListScroll(0)"
    />
  </v-navigation-drawer>

  <div v-if="showEmptyState" class="flex h-full flex-col items-center justify-center">
    <img :src="SearchEmptyState" class="w-full max-w-[415px] p-4" />
    <p class="my-4 text-center text-sm leading-[21px] text-shade-800">
      Build your talent pipeline with access to our 2+ million<br />
      manufacturing professionals
    </p>

    <div
      class="cursor-pointer text-center text-sm font-bold leading-[21px] text-highlight-500"
      @click="() => searchFormRef?.focusKeywordInput()"
    >
      Start search
    </div>
  </div>

  <template v-else>
    <div v-show="!isLoading" class="min-h-screen bg-tint-0">
      <div v-if="searchService.serverItems" class="results-header">
        <!-- Candidates Count -->
        <span class="text-sm leading-[18px]">{{ resultsHeaderText }}</span>

        <!-- Bulk Messaging Button -->
        <button
          v-if="showBulkMessageButton"
          class="max-h-[32px] items-center rounded-[6px] bg-highlight-600 px-3 py-2 leading-4 disabled:opacity-75"
          :disabled="isUnlockingUserProfiles"
          @click="openMessaging({ source: ProUnlockSource.BULK_MESSAGING })"
        >
          <template v-if="isUnlockingUserProfiles">
            <SpinnerLoader class="mx-4 h-[18px] w-[18px]" dark></SpinnerLoader>
          </template>
          <template v-else>
            {{ bulkUnlockButtonMessage }}
          </template>
        </button>
      </div>

      <!-- Candidates List -->
      <div class="candidates-list" @scroll="onCandidateListScroll">
        <ProSearchResults
          :is-loading="sharedSearchService.isSearchingCandidates"
          @change:page="handleResultsPageChange"
          @change:items-per-page="handleResultsItemsPerPageChange"
          @open:messaging="openMessaging({ source: ProUnlockSource.PPC })"
        />
      </div>
    </div>

    <div v-show="isLoading" class="flex min-h-screen items-center justify-center">
      <SpinnerLoader class="h-8 w-8" />
    </div>
  </template>

  <AddToJobModal
    :open="openMessagingDialog"
    :show-message-field="true"
    :pros="selectedPros"
    @submit="onAddProToJobModalSubmit"
    @close="onAddProToJobModalClose"
    @remove-pro="onAddProToJobModalRemovePro($event)"
  />
</template>

<style scoped lang="postcss">
.results-header {
  @apply sticky top-0 z-10 flex h-[60px] items-center justify-between bg-tint-0 px-8 pb-8 pt-10;
}
.candidates-list {
  @apply h-[calc(100vh-74px)] overflow-y-auto px-8 pb-4 pt-0.5;
}
</style>
